import React, { useState, useEffect, useRef } from 'react'

import RatesTableBlock from './RatesTableBlock'
import WellbySelect from '../../Utilities/WellbySelectDropdown/WellbySelectDropdownComponent'
import Select from 'react-select' // uses this package https://react-select.com/
import { Tooltip } from 'Components'

import api from 'Helpers/api'

import ChevronDownSVG from 'SVGs/chevronDown.svg'
import DownloadSVG from 'SVGs/download.svg'
import InfoCircleSquigglySVG from 'SVGs/infoCircleSquiggly.svg'
import ExclamationCircle from 'SVGs/exclamationCircle.svg'
import PlusSVG from 'SVGs/plus.svg'
import SyncSVG from 'SVGs/sync.svg'
import XMarkSVG from 'SVGs/x-mark.svg'


const DesktopRatesContainer = ({ tableData, rateTableOptions, pdfGeneratorLink, printToPdfPage, dropdownHeading, wasTabClicked, activeIndex, setActiveIndex }) => {
    const { Text, TemplateName } = pdfGeneratorLink
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(rateTableOptions)
    const [value, setValue] = useState(rateTableOptions[activeIndex])

    async function handlePdfGeneration(e) {
        e.preventDefault()
        setLoading(true)
        // if TemplateName is not set, then use the default value of 'Deposit Rates'
        let templateNameValue = TemplateName ? TemplateName : 'Deposit Rates'
        let urlToOpen = '/' // default to home page
        await api.getPdfLink(templateNameValue)
            .then(res => {
                setLoading(false)
                urlToOpen = res.Response
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
        setTimeout(() => {
            window.open(urlToOpen, '_blank')
        }, 200);
    }

    function onSelectOptionChanged(option) {
        setActiveIndex(option.Index)
        setValue(option)
        updateUrl(option)
    }

    function updateUrl(option) {
        const parsedUrl = new URL(window.location.href)
        // add to search params
        parsedUrl.searchParams.set('AccountType', option.ContainerTitle)
        parsedUrl.searchParams.set('Product', option.Title)
        // update url
        window.history.pushState({}, '', parsedUrl)
    }

    useEffect(() => {
        // we decided not to change the url on page load. only after a tab is clicked
        if (wasTabClicked) {
            updateUrl(value)
        }
    }, [value])

    return (
        <>
            {dropdownHeading &&
                <div className='mb-4 text-violet font-bold'>{dropdownHeading}</div>
            }
            <div className='flex gap-8 mb-8'>
                <WellbySelect
                    showClearButton={false}
                    showDivider={false}
                    options={options}
                    value={value}
                    onChange={option => onSelectOptionChanged(option)}
                />
                {printToPdfPage
                    ? <a href={printToPdfPage.Url} target='_blank' className='flex items-center gap-2 rounded-full font-medium px-8 border-2 border-violet hover:border-violet-light text-violet'>
                        <div>Print PDF</div>
                        <DownloadSVG />
                    </a>
                    : <button className='flex items-center gap-2 rounded-full font-medium px-8 border-2 border-violet hover:border-violet-light text-violet'
                        onClick={(e) => handlePdfGeneration(e)}
                    >
                        <>
                            <div>{Text || 'Download'}</div>
                            {!loading ? <DownloadSVG /> : <SyncSVG className="md:w-4 w-3 my-0.5 animate-spin" />}
                        </>
                    </button>
                }
            </div>
            <div>
                {<RatesTableBlock data={tableData} />}
            </div>
        </>
    )
}

const MobileRatesContainer = ({ ratesContainerData, tabName, activeIndex, setActiveIndex, hasTableFromUrl, pdfGeneratorLink, printToPdfPage }) => {
    const [selectedProductRateTableIndex, setSelectedProductRateTableIndex] = useState(null)

    const [isProductTableSelected, setIsProductTableSelected] = useState(false)
    const [isSubAccountTypeSelected, setIsSubAccountTypeSelected] = useState(false)

    const [rateTableData, setRateTableData] = useState(null)
    const [subAccountDetailViewData, setSubAccountDetailViewData] = useState(null)
    const [selectDropdownData, setSelectDropdownData] = useState(null)
    const [filteredColumnHeaderTitleKeys, setFilteredColumnHeaderTitleKeys] = useState(null)
    const [filteredColumnHeaderTitles, setFilteredColumnHeaderTitles] = useState(null)
    const [filteredColumnValueKeys, setFilteredColumnValueKeys] = useState(null)
    const [allColumnKeys, setAllColumnKeys] = useState(null)

    const [featuredColumns, setFeaturedColumns] = useState(null)
    const [featuredColumnsKeys, setFeaturedColumnsKeys] = useState(null)
    const [featuredColumnsData, setFeaturedColumnsData] = useState(null)

    const productRateTables = ratesContainerData?.ProductsContentArea;
    const productRateTableTitles = productRateTables?.map((item) => item.Title)

    const { Text, TemplateName } = pdfGeneratorLink
    const [loading, setLoading] = useState(false)
    const [showPdfLink, setShowPdfLink] = useState(false)
    let pdfLinkRef = useRef('/') // default to home page

    useEffect(() => {
        if (hasTableFromUrl) {
            onProductTableClicked(activeIndex)
        }
    }, [])

    async function handlePdfGeneration(e) {
        e.preventDefault()
        setLoading(true)
        // if TemplateName is not set, then use the default value of 'Deposit Rates'
        let templateNameValue = TemplateName ? TemplateName : 'Deposit Rates'

        await api.getPdfLink(templateNameValue)
            .then(res => {
                setLoading(false)
                pdfLinkRef.current = res.Response
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
        const isiOSAndSafari = isMobileSafari()
        if (isiOSAndSafari) {
            setShowPdfLink(true)
            return
        }
        setTimeout(() => {
            window.open(pdfLinkRef.current, '_blank')
        }, 200);
    }

    function isMobileSafari() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isiOSDevice = /ipad|iphone|ipod/.test(userAgent);
        const isSafari = /safari/.test(userAgent);
        return isiOSDevice && isSafari;
    }

    function onProductTableClicked(index) {
        // if the same account type is clicked, unselect it
        if (isProductTableSelected && index == selectedProductRateTableIndex) {
            setIsProductTableSelected(false)
            return
        }

        let rateTableData = ratesContainerData.ProductsContentArea[index];

        let subAccountData = rateTableData?.BodyData?.Value[0];
        setSubAccountDetailViewData(subAccountData)
        setIsSubAccountTypeSelected(true)

        const parsedUrl = new URL(window.location.href)
        // add to search params
        parsedUrl.searchParams.set('Product', rateTableData.Title)
        // update url
        window.history.pushState({}, '', parsedUrl)

        setupData(rateTableData)

        setIsProductTableSelected(true)
        setSelectedProductRateTableIndex(index)
        setRateTableData(rateTableData)
    }

    function setupData(rateTableData) {
        const rateTableHeaderData = rateTableData?.HeaderData?.Value?.[0]

        let allColumnKeys = Object.keys(rateTableHeaderData)
        allColumnKeys = allColumnKeys.filter((key) => key.includes('Column') && key.includes('Title'))
        allColumnKeys = allColumnKeys.filter(key => rateTableHeaderData[key] !== null && rateTableHeaderData[key] !== '')
        allColumnKeys = allColumnKeys.map((key) => key.substring(0, 7))
        setAllColumnKeys(allColumnKeys)

        let featureInMobileKeys = Object.keys(rateTableHeaderData)
        featureInMobileKeys = featureInMobileKeys.filter((key) => key.includes('FeatureInMobile'))

        // get the first 2 column keys where Column#FeaturedInMobile key is true
        let featuredColumnsKeys = []; // will only ever have 2 items
        featureInMobileKeys.forEach((key) => {
            if (featuredColumnsKeys.length >= 2) return

            if (rateTableHeaderData[key] == true) {
                featuredColumnsKeys.push(key.slice(0, 7)) // only return the Column# part of the key
            }
        })

        // if there are not at least 2 featured featuredColumnsKeys selected, take the first 2 featuredColumnsKeys
        if (featuredColumnsKeys.length < 2) {
            featuredColumnsKeys = [];
            featureInMobileKeys.forEach((key) => {
                if (featuredColumnsKeys.length >= 2) return

                featuredColumnsKeys.push(key.slice(0, 7)) // only return the Column# part of the key
            })
        }

        setFeaturedColumnsKeys(featuredColumnsKeys)

        let columnNames = [];
        featuredColumnsKeys.forEach((column) => {
            // if (columnNames.length >= 2) return // only take first 2 featuredColumnsKeys that are featured in mobile
            columnNames.push(rateTableHeaderData[`${column}Title`])
        })

        setFeaturedColumns(columnNames)

        let featuredColumnData = [];
        const rateTableBodyData = rateTableData?.BodyData?.Value
        rateTableBodyData?.map((item, i) => {
            let columnDataItem = {
                leftSideData: item[`${featuredColumnsKeys[0]}Value`],
                rightSideData: item[`${featuredColumnsKeys[1]}Value`]
            }
            featuredColumnData.push(columnDataItem)
        })
        setFeaturedColumnsData(featuredColumnData)

        let selectDropdownData = rateTableBodyData?.map((item, i) => {
            var leftSideData = removeElementTags(item[`${featuredColumnsKeys[0]}Value`])
            var rightSideData = removeElementTags(item[`${featuredColumnsKeys[1]}Value`])
            if (leftSideData == 'null') { leftSideData = '' }
            if (rightSideData == 'null') { rightSideData = '' }
            return {
                leftSideData: leftSideData,
                rightSideData: rightSideData,
                label: leftSideData,
                value: rightSideData,
                index: i,
            }
        })
        setSelectDropdownData(selectDropdownData)
    }

    function removeElementTags(inputString) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(inputString, "text/html");
        return doc.body.textContent || "";
    }

    function onSubAccountTypeClicked(option) {
        let subAccountData = rateTableData?.BodyData?.Value[option.index];
        if (!subAccountData) return

        const tableHeaderData = rateTableData.HeaderData.Value[0]

        let columnHeaderTitleKeys = Object.keys(tableHeaderData);

        // filter out the keys that don't contain the word Title to get an array of only the column titles
        columnHeaderTitleKeys = columnHeaderTitleKeys.filter(key => key.includes('Title') && tableHeaderData[key] != '')
        let columnValueKeys = columnHeaderTitleKeys.map((key) => key.replace('Title', 'Value'))

        setIsSubAccountTypeSelected(true)
        setSubAccountDetailViewData(subAccountData)
        setFilteredColumnHeaderTitleKeys(columnHeaderTitleKeys)
        setFilteredColumnValueKeys(columnValueKeys)
        let columnNames = columnHeaderTitleKeys.map((key) => tableHeaderData[key])
        setFilteredColumnHeaderTitles(columnNames)
    }

    const SubAccountDetailView = ({ subAccountData, columnNames, fullRateTableData }) => {
        if (subAccountData == null) return null
        let fullRateTableHeaderData = fullRateTableData.HeaderData.Value[0]

        return (
            <div className='w-full'>
                {allColumnKeys.map((key, i) => {
                    return (
                        <div key={i} className='flex override-ul-list gap-4'>
                            <div className='py-4 w-full'>
                                <div className='flex gap-2'>
                                    {fullRateTableHeaderData[key + "Tooltip"] ?
                                        <>
                                            <div className='flex items-center text-violet'>
                                                <Tooltip tooltipText={fullRateTableHeaderData[key + "Tooltip"]}>
                                                    <InfoCircleSquigglySVG className="w-6 inline-block" />
                                                </Tooltip>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='min-w-[24px]'>
                                            </div>
                                        </>
                                    }
                                    <div className='font-bold text-lg' dangerouslySetInnerHTML={{ __html: fullRateTableData.HeaderData.Value[0][key + "Title"] }} />
                                </div>
                            </div>
                            <div className='py-4 w-full text-lg'>
                                <div dangerouslySetInnerHTML={{ __html: subAccountData[key + "Value"] || '' }} />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const CustomMenuList = ({ innerRef, innerProps, children }) => {
        return (
            <div ref={innerRef} {...innerProps} className='overflow-auto max-h-[320px] scroll-smooth'>
                <div className='wrapper grid grid-cols-2'>
                    <div className='bg-[#21064C] text-white pl-6 flex justify-center flex-col'>
                        {featuredColumns[0]}
                    </div>
                    <div className='bg-[#21064C] text-white text-right pr-6 flex justify-center flex-col'>
                        {featuredColumns[1]}
                    </div>

                    {children.map((child, i) => {
                        return (
                            <div key={i} className='col-span-2'>
                                {child}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const CustomOption = ({ innerRef, innerProps, data, isSelected }) => {
        var backgroundColor = data.index % 2 === 0 ? 'bg-white' : 'bg-[#D3D9FF]'
        return (
            <div ref={innerRef} {...innerProps}>
                <div className={`option grid grid-cols-2 py-4 px-6 hover:bg-gray-200 hover:cursor-pointer relative font-display ${isSelected ? 'purple-circle' : ''} ${backgroundColor} bg-opacity-20`} >
                    <div className='font-medium'>
                        {data.leftSideData}
                    </div>
                    <div className='font-normal text-right'>
                        {data.rightSideData}
                    </div>
                </div>
            </div>
        )
    }

    const CustomDropdownIndicator = ({ selectProps }) => {
        return (
            <div className='text-violet mr-2'>
                <ChevronDownSVG className={`w-6 duration-150 ${selectProps.menuIsOpen ? 'rotate-180' : ''}`} />
            </div>
        )
    }


    return (
        <div>
            <div className='flex justify-between items-center bg-[#9966FF] text-white py-4 px-6'>
                <div>
                    {tabName}
                </div>
                {printToPdfPage
                    ? <a href={printToPdfPage.Url} target='_blank' className='flex items-center gap-2 rounded-full font-medium px-6 py-1 bg-white text-violet'>
                        <div>Print PDF</div>
                        <DownloadSVG className="w-4" />
                    </a>
                    : <button className='flex items-center gap-2 rounded-full font-medium px-6 py-1 bg-white text-violet'
                        onClick={(e) => handlePdfGeneration(e)}
                    >
                        <>
                            <div>{Text || 'Download'}</div>
                            {!loading ? <DownloadSVG className="w-4" /> : <SyncSVG className="w-4 my-0.5 animate-spin" />}
                        </>
                    </button>
                }
            </div>
            {showPdfLink &&
                <div className='bg-[#21064C] text-white p-6'>
                    <div className='font-bold'>
                        Click link to download rates PDF:
                    </div>
                    <div className='text-[#FFA600] underline'>
                        <a href={pdfLinkRef.current} target='_blank'>
                            https://www.wellbyfinancial.com/rates-table/download
                        </a>
                    </div>
                </div>
            }
            <div className='bg-violet text-white py-4'>
                <div className='flex gap-2 ml-6'>
                    <div>
                        <ExclamationCircle className="w-6 h-full" />
                    </div>
                    <div>Expand item to view details.</div>
                </div>
            </div>
            {productRateTableTitles?.map((item, i) => {
                return (
                    <div key={i}>
                        <div
                            onClick={() => onProductTableClicked(i)}
                            className='flex justify-between p-6 border-b-2 border-gray-300 hover:cursor-pointer hover:bg-gray-100'>
                            <div className='font-bold text-lg'>
                                {item}
                            </div>
                            {isProductTableSelected && i == selectedProductRateTableIndex ?
                                <XMarkSVG className="text-violet" width="20" height="20" /> : <PlusSVG className="text-violet" width="20" height="20" />}
                        </div>
                        {isProductTableSelected && i == selectedProductRateTableIndex &&
                            <>
                                <div className='px-6 my-4'>
                                    <div className='mb-2'>Select a {featuredColumns[0]}</div>
                                    <Select options={selectDropdownData}
                                        defaultValue={selectDropdownData[0]}
                                        components={{
                                            MenuList: CustomMenuList,
                                            Option: CustomOption,
                                            DropdownIndicator: CustomDropdownIndicator,
                                        }}
                                        onChange={(option) => onSubAccountTypeClicked(option)}
                                        isSearchable={false}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '10px',
                                                borderRadius: '10px',
                                            }),
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: '#430883',
                                                fontWeight: '600',
                                            }),
                                            indicatorSeparator: () => null,
                                            menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                marginTop: '0px',
                                            }),
                                        }}
                                    />
                                    {isSubAccountTypeSelected &&
                                        <SubAccountDetailView subAccountData={subAccountDetailViewData} columnNames={filteredColumnHeaderTitles} fullRateTableData={rateTableData} />
                                    }
                                </div>
                            </>
                        }
                    </div>
                )
            })}
        </div>
    )
}

const RatesContainerBlock = ({ data, isMobile, tabName, productTableFromUrl, wasTabClicked }) => {
    // stops old instances of RateTablewithHoveroverBlock from rendering
    if (!data.ProductsContentArea) return null

    const { Title, DropdownHeading, ProductsContentArea, PdfGeneratorLink, PrintToPdfPage } = data
    const productTableIndex = ProductsContentArea.map((item) => item.Title).indexOf(productTableFromUrl)
    const hasTableFromUrl = productTableIndex >= 0
    const [activeIndex, setActiveIndex] = useState(productTableIndex >= 0 ? productTableIndex : 0)

    let rateTableOptions = ProductsContentArea.map((item, i) => {
        let column = `Product${i + 1}`
        return {
            label: item.Title,
            value: `${column}${item.Title}`,

            ContainerTitle: Title,
            Title: item.Title,
            Index: i,
        }
    })

    return (
        <div>
            {isMobile === true ?
                <div>
                    <MobileRatesContainer
                        ratesContainerData={data}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        tabName={tabName}
                        hasTableFromUrl={hasTableFromUrl}
                        pdfGeneratorLink={PdfGeneratorLink}
                        printToPdfPage={PrintToPdfPage} />
                </div>
                :
                <div>
                    <DesktopRatesContainer
                        tableData={ProductsContentArea[activeIndex]}
                        rateTableOptions={rateTableOptions}
                        pdfGeneratorLink={PdfGeneratorLink}
                        printToPdfPage={PrintToPdfPage}
                        dropdownHeading={DropdownHeading}
                        wasTabClicked={wasTabClicked}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex} />
                </div>}
        </div>
    )
}

export default React.memo(RatesContainerBlock)