const getMonthName = (date) =>
  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]

const getDateWithFormat = (date) => `${getMonthName(date)} ${date.getDate()}, ${date.getFullYear()}`

const formatAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const addWebPToImageURL = (url) => {
  if (!url) return '';
  return url + "?format=webp";
}

export {
  formatAMPM,
  getMonthName,
  getDateWithFormat,
  addWebPToImageURL
}
