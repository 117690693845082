import React, { useState } from 'react'

import { StarsBackground, RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

import DataRowRenderer from './DataRowRenderer'

const ColumnHeader = ({ colName, colHeader, inEditMode }) => {
  return (
    <h3 data-epi-edit={colName}
      className="mt-3 font-semibold cursor-pointer md:cursor-default">
      {(colHeader ?? '') === '' && inEditMode
        ? <span className="text-sm text-gray-500">Click to edit</span>
        : <span className="text-base lg:text-xl">{colHeader}</span>}
    </h3>
  )
}

const ColumnWrapper = ({ colName, rowData, inEditMode, active, isFirstColumn }) => {
  return (
    <>
      <div data-epi-edit={colName} className="hidden mt-2 md:grid">
        <DataRowRenderer rowData={rowData} inEditMode={inEditMode} bgViolet={true} />
        {isFirstColumn && (
          <button className="bg-white text-[#54139F] hover:bg-[#B6B8FF] border-1 border-white hover:border-[#B6B8FF] rounded-3xl w-fit py-2 px-6 mt-5 transition-colors ease-in font-semibold"
          onClick={() => {
            const smVisitorCode = document.createElement('sm-visitor-code')
            document.body.appendChild(smVisitorCode)
          }}>
          Get CoBrowsing Code
        </button>
        )}
      </div>
      <div data-epi-edit={colName} className={`${active ? 'md:hidden grid' : 'hidden'} mt-2`}>
        <DataRowRenderer rowData={rowData} inEditMode={inEditMode} bgViolet={true} />
        {isFirstColumn && (
          <button className="bg-white text-[#54139F] hover:bg-[#B6B8FF] border-1 border-white hover:border-[#B6B8FF] rounded-3xl w-fit py-2 px-6 mt-5 transition-colors ease-in font-semibold"
          onClick={() => {
            const smVisitorCode = document.createElement('sm-visitor-code')
            document.body.appendChild(smVisitorCode)
          }}>
          Get CoBrowsing Code
        </button>
        )}
      </div>
    </>
  )
}

const FooterMain = ({ data, inEditMode }) => {
  const { DataMapper, NcuaImage, SocialHeader, SocialLinks, CopyrightText } = data
  const shouldRenderData = (rowData, colHeader, inEditMode) => {
    return (colHeader ?? '') !== '' || (rowData?.length > 0) || inEditMode
  }

  // this is to expand on mobile view
  const [activeIndex, setActiveIndex] = useState(null)

  const year = new Date().getFullYear();

  return (
    <footer className="overflow-y-hidden bg-gradient-to-r from-violet-dark via-violet to-violet-bright">
      <StarsBackground />
      <div className="container relative mx-auto max-w-screen-2xl">
        <div className="px-4 pt-6 lg:pt-14 xl:px-28 lg:px-20 md:px-10 sm:px-8">
          <div className="relative grid justify-center gap-4 text-center text-white md:flex md:justify-between font-display md:text-left">
            {Object.entries(DataMapper).map((val, i) => {
              const [colName, colData] = val
              const rowData = colData[Object.keys(colData)[0]]
              const colHeader = colData[Object.keys(colData)[1]]
              if (shouldRenderData(rowData, colHeader, inEditMode)) {
                if (i < Object.entries(DataMapper).length - 1) {
                  return (
                    <div key={i} onClick={() => {
                      if (activeIndex === i) {
                        setActiveIndex(null)
                        return
                      }
                      setActiveIndex(i)
                    }}>
                      <ColumnHeader colName={`${colName}Header`} colHeader={colHeader} inEditMode={inEditMode} />
                      <ColumnWrapper colName={colName} rowData={rowData} active={activeIndex === i || colHeader.trim() === ''} inEditMode={inEditMode} isFirstColumn={i === 0} />
                    </div>
                  )
                }

                // Render last column with social links
                return (
                  <div className="flex flex-col gap-4 lg:gap-6" key={i}>
                    <div className="md:hidden" onClick={() => {
                      if (activeIndex === i) {
                        setActiveIndex(null)
                        return
                      }
                      setActiveIndex(i)
                    }}>
                      <ColumnHeader colName={`${colName}Header`} colHeader={colHeader} inEditMode={inEditMode} />
                      <ColumnWrapper colName={colName} rowData={rowData} active={activeIndex === i || colHeader.trim() === ''} inEditMode={inEditMode} />
                    </div>
                    <div>
                      <ColumnHeader colName="SocialHeader" colHeader={SocialHeader} inEditMode={inEditMode} />
                      <div data-epi-edit="SocialLinks" className="flex justify-center gap-4 mt-4 lg:gap-6 md:justify-start">
                        {SocialLinks && SocialLinks.length > 0 && SocialLinks.map((dat, idx) => {
                          const { Icon, Link } = dat
                          return (
                            <RouterLinkTemplate Path={Link} inEditMode={inEditMode} key={idx} Target="_blank">
                              <img data-src={Icon} alt="Social Icon" width="16" height="16" className='lazy' />
                            </RouterLinkTemplate>
                          )
                        })}
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <ColumnHeader colName={`${colName}Header`} colHeader={colHeader} inEditMode={inEditMode} />
                      <ColumnWrapper colName={colName} rowData={rowData} inEditMode={inEditMode} />
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
            <div className='mb-4'>
              <div className="flex justify-center mt-6 border-t border-gold">
                <div className="items-center">
                  {(NcuaImage || inEditMode) && (
                  <div className="flex justify-center">
                    <img data-src={addWebPToImageURL(NcuaImage?.Url)} alt={NcuaImage?.AltTag} data-epi-edit='NcuaImage' width="400" height="45" className="m-4 lazy lg:mx-0" />
                  </div>
                  )}
                <div className={`flex flex-col justify-center items-center text-white text-xs text-center ${!NcuaImage ? 'mt-6': ''}`}>
                  <div>
                    Copyright &copy; {year} Wellby Financial. All rights reserved.
                  </div>
                <div className="contents" data-epi-edit="CopyrightText" dangerouslySetInnerHTML={{ __html: CopyrightText }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(FooterMain)
