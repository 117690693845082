import React, { useEffect } from 'react'

import ContentArea from 'Components/ContentArea'
import { AuthorProfile } from 'Blocks/Blog'
import { getDateWithFormat } from 'Helpers/utilities'
import { BlockCard } from 'Blocks/BlogSearchWithRelatedArticlesCardBlock'
import FaceBookShare from 'SVGs/facebook.svg'
import TwitterShare from 'SVGs/twitter.svg'
import LinkedInShare from 'SVGs/linkedIn.svg'
import EmailInShare from 'SVGs/email.svg'
import EmbedFormBlock from '../blocks/Forms/EmbedFormBlock/EmbedFormBlock'
import { addWebPToImageURL } from 'Helpers/utilities'
import './BlogArticlePage.css'

const BlogArticlePage = ({ data, inEditMode }) => {
    const { MetaTitle, MetaDescription, FeaturedImage, Heading, StartPublish, MainContentArea, Category, Author, BlogParentPages, RelatedArticles } = data
    const blogParents = BlogParentPages?.reduce((result, curr) => {
        if (curr.Category?.length) {
            result[curr.Category[0]] = curr;
        }
        return result;
    }, {}) || {};
  const openTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${MetaTitle} ${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openFacebookShare = () => {
    window.open(
      `http://www.facebook.com/sharer.php?s=100&p[title]=${MetaTitle}&p[url]=${window.location.href}&p[summary]=${MetaDescription}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openGmailShare = () => {
    window.open(
      `mailto:?subject=${MetaTitle}&body=${MetaDescription} ${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }

  const formEmbedCode = "<script charset=\"utf-8\" type=\"text/javascript\" src=\"//js.hsforms.net/forms/embed/v2.js\"></script>\n<script>\n  hbspt.forms.create({\n    region: \"na1\",\n    portalId: \"21544839\",\n    formId: \"cd6a1d04-4d66-42f8-91eb-f32aa89d71eb\"\n  });\n</script>"

  return (
    <div className="container max-w-screen-xl px-4 mx-auto lg:px-12 md:px-8 blogArticlePage">
      <div className="mt-6 -z-10 aspect-w-14 aspect-h-5">
        <img data-epi-edit="FeaturedImage" data-src={addWebPToImageURL(FeaturedImage?.Url)} alt={FeaturedImage?.AltTag} width="1100" height="450" className="items-center hidden rounded-md lazy md:block" />
        <img data-epi-edit="FeaturedImage" data-src={addWebPToImageURL(FeaturedImage?.Url)} alt={FeaturedImage?.AltTag} width="768" height="432" className="rounded-md lazy md:hidden" />
      </div>
        <div className="xl:mx-28 lg:mx-24 md:mx-16 xl:-mt-24 lg:-mt-20 md:-mt-12 md:bg-white md:rounded-t-lg md:px-12">
          <h1 className="pt-4 text-2xl font-medium md:pt-8 md:pb-6 lg:text-5xl md:text-3xl font-display tracking-tightest">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h1>
          <p className="font-display">{getDateWithFormat(new Date(StartPublish))}</p>
          {Author?.PublicName && <p className="mt-5 text-lg font-medium font-display md:mt-8" data-epi-edit="Author">By {Author?.PublicName}</p>}
        </div>
        <div className="md:float-left lg:ml-8 md:ml-4">
          <div className="mt-5 md:grid md:grid-rows-4 md:mt-0">
            <button className="mb-1 mr-4 rounded md:mx-0" onClick={() => openFacebookShare()}>
              <FaceBookShare className="w-5 h-5 text-violet"/>
            </button>
            <button onClick={() => openTwitterShare()} >
              <TwitterShare className="w-5 h-5 text-violet" />
            </button>
            <button className="mx-4 my-1 md:mx-0" onClick={() => openLinkedInShare()} >
              <LinkedInShare className="w-5 h-5 text-violet" />
            </button>
            <button onClick={() => openGmailShare()} >
              <EmailInShare className="w-5 h-5 text-violet" />
            </button>
          </div>
        </div>
        <div className="lg:mx-8 md:mx-14">
          <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
        </div>
        {Category?.length > 0 && (
              <div className="py-4 xl:mx-20 lg:mx-16 md:mx-8 md:px-12 sm:mx-8 lg:py-8 md:py-6">
                  <h2 className="mb-3 text-xl font-medium text-gray-500 font-body">Related Topics</h2>
                  <div className="flex flex-wrap gap-3"> 
                    {Category.map((item, i) =>
                        <a key={i} className="px-4 py-1 border rounded-full text-violet whitespace-nowrap border-violet" href={blogParents[item?.Id]?.Url}>{item?.Description}</a>
                    )}
                </div>
            </div>
        )}
        <AuthorProfile data={Author} inEditMode={inEditMode} />
        {RelatedArticles?.length > 0 && 
              <div className="py-4 xl:mx-28 lg:mx-20 md:mx-12 md:px-12 sm:mx-8 lg:py-8 md:py-6">
                  <h2 className="mb-8 text-xl font-medium lg:text-4xl font-body">Related articles you might like</h2>
                  <div className="flex flex-wrap my-6 -mx-1 lg:-mx-4">
                    {RelatedArticles.slice(0, 3).map((item, i) => {
                        return <BlockCard key={i} data={item} inEditMode={inEditMode} readText={'Read'} className="md:px-4" />
                    })}
                  </div>
              </div>
        }
        <div className="mb-12 md:mb-20">
          <EmbedFormBlock data={formEmbedCode} givenCss={"blog mx-auto"}/>
        </div>
    </div>
  )
}

export default React.memo(BlogArticlePage)
