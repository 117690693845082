import React, { useContext, useEffect, useRef } from 'react'
import { AppContext } from '../contexts'
import { useReactToPrint } from 'react-to-print'

const PrintRatesPage = ({ data, inEditMode }) => {
    const { MainContentArea, LogoImage, PrintRatesDisclaimer, EffectiveDate, Disclosures } = data

    const { appContext, setAppContext } = useContext(AppContext)
    useEffect(() => setAppContext({ ...appContext, header: null, footer: null }), [])

    const componentRef = useRef();
    const printPdf = useReactToPrint({
        content: () => componentRef.current
    })

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printPdf();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [printPdf]);

    const transformProductContentBlock = (productContentBlock) => {
        const headerData = productContentBlock.HeaderData.Value[0];
        const bodyData = productContentBlock.BodyData.Value;
        const maxColumns = Object.keys(headerData).filter(key => key.includes('Title')).length; // always 8 max columns

        const headers = [];
        for (let i = 1; i <= maxColumns; i++) { // filter out null titles to condense headers into only the ones with titles
            const titleKey = `Column${i}Title`;
            if (headerData[titleKey]) {
                headers.push({
                    title: headerData[titleKey],
                    key: `Column${i}Value`
                });
            }
        }

        const rows = bodyData.map((row) => {
            return headers.map((header) => ({
                value: row[header.key]
            }));
        });

        return { headers, rows };
    };

    return (
        <>
            <div className='flex justify-center items-center my-8'>
                <button type='button' onClick={() => printPdf()} className='rounded-full font-medium font-body whitespace-nowrap bg-violet hover:bg-violet-dark border-2 border-violet hover:border-violet-dark text-white text-sm py-1 px-6 md:text-base md:py-2 md:px-8'>Print PDF</button>
            </div>
            <div ref={componentRef} className='print-rates-page-wrapper px-4 md:px-14'>
                <style dangerouslySetInnerHTML={{
                    __html: `
                    header {
                        display: none; /* prevent layout shift */
                    }
                    .markdown h2 {
                        margin-bottom: 0;
                    }
                    @page {
                        // margin-top: 50px; /* margin for spacing from top of page */
                        // margin-bottom: 50px; /* margin for spacing from bottom of page */
                        size: landscape; /* set to landscape for better table layout */
                    }
                    @media print {
                        .head-section {
                            min-height: unset;
                            flex-direction: row !important;
                        }
                        img {
                            max-height: 100px;
                            max-width: 200px;
                        }
                        .table-wrapper {
                            page-break-inside: avoid; /* makes sure tables don't break in the middle of a row */
                        }
                        .first-table {
                            page-break-before: auto; /* makes sure the first table doesn't start on a new page */
                        }
                        .disclosures {
                            page-break-before: always; /* makes sure the disclosures section starts on a new page */
                        }
                    }
                `}} />
                <div className='head-section flex flex-col md:flex-row items-center gap-12 min-h-[160px]'>
                    {LogoImage?.Url && 
                        <img src={LogoImage?.Url} alt='wellby logo' className='max-h-20' />
                    }
                    <div dangerouslySetInnerHTML={{ __html: PrintRatesDisclaimer }} className='text-xl markdown lg:prose prose-sm'></div>
                    <div className='text-xl whitespace-nowrap uppercase font-bold md:ml-auto'>
                        {EffectiveDate &&
                            <span>Effective Date: {EffectiveDate}</span>
                        }
                    </div>
                </div>
                {MainContentArea?.map((contentArea, index) => (
                    <div key={index} className='' style={{ pageBreakBefore: index === 0 ? 'auto' : 'always' }}>
                        <div className='text-5xl font-bold my-4'>{contentArea.Title}</div>

                        <div className=''>
                            {contentArea?.ProductsContentArea.map((productContentBlock, index) => {
                                const { headers, rows } = transformProductContentBlock(productContentBlock);
                                return (
                                    <div key={index} className={` ${index === 0 ? 'first-table' : 'table-wrapper'}`}>
                                        <div className='text-2xl font-bold mt-8 mb-4'>{productContentBlock.Title}</div>
                                        <table className={`w-full bg-white text-xs whitespace-normal shadow-md`}>
                                            <thead className='text-white font-bold text-left'>
                                                <tr>
                                                    {headers.map((headerColumn, index) => (
                                                        <th
                                                            className={`bg-violet py-5 px-4 whitespace-normal ${index === 0 ? 'rounded-tl-lg' : ''} ${index === headers.length - 1 ? 'rounded-tr-lg' : ''}`}
                                                            key={index}
                                                        >
                                                            <div className='flex gap-4'>
                                                                <div>
                                                                    {headerColumn.title}
                                                                </div>
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {rows !== null && rows.length > 0 && rows.map((rowData, index) => {
                                                        let backgroundColor = index % 2 === 0 ? 'bg-white' : 'bg-[#D3D9FF] bg-opacity-40'
                                                        return (
                                                            <tr key={index} className={`${backgroundColor}`}>
                                                                {rowData.map((columnData, index2) => {
                                                                    const value = columnData.value
                                                                    return (
                                                                        <td key={index2}
                                                                            className='override-ul-list py-5 px-4'
                                                                        >
                                                                            <div dangerouslySetInnerHTML={{ __html: value }} />
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            </tbody>
                                        </table>
                                    </div>)
                            })}
                        </div>
                    </div>
                ))}
                <div className='disclosures mt-8'>
                    <div dangerouslySetInnerHTML={{ __html: Disclosures }}></div>
                </div>
            </div>
        </>
    )
}

export default React.memo(PrintRatesPage)
