import React from 'react'

import { RouterLinkTemplate } from 'Components'

const AuthorProfile = ({ data, inEditMode }) => {
  const { AboutTheAuthorText, PublicName, SocialLinks, Bio, Avatar } = data
  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-0
                      lg:py-10 md:py-7 py-4
                      md:flex">
        <img data-src={Avatar?.Url} alt={Avatar?.AltTag} className="lazy md:mx-8 md:my-0 my-4 place-self-center" width="200" height="200" data-epi-edit="Avatar"/>
        <div className="w-full place-self-center">
          <span className="md:text-2xl text-xl font-medium font-display mb-4 md:mx-0 mx-0" data-epi-edit="AboutTheAuthorText">{AboutTheAuthorText}</span>
          <div className="font-body md:text-lg text-base md:mx-0 mx-0" data-epi-edit="Bio" dangerouslySetInnerHTML={{ __html: Bio }} />
          <div data-epi-edit="SocialLinks" className="flex mt-4 lg:gap-6 gap-3 md:justify-start justify-center">
            {SocialLinks && SocialLinks.length > 0 && SocialLinks.map((dat, idx) => {
              const { Icon, Link } = dat
              return (
                <RouterLinkTemplate Path={Link} inEditMode={inEditMode} key={idx} Target="_blank">
                  <img data-src={Icon} alt="Social Icon" width="24" height="24" className='lazy' />
                </RouterLinkTemplate>
              )
            })}
          </div>
        </div>
      </div>
      {(inEditMode || !PublicName) && <span className="text-sm text-gray-500" data-epi-edit="PublicName">Edit public name</span>}
    </div>
  )
}

export default React.memo(AuthorProfile)
