import BlogArticlePage from './BlogArticlePage'
import BlogParentPage from './BlogParentPage'
import GrandChildPage from './GrandChildPage'
import LandingPage from './LandingPage'
import OfferPage from './OfferPage'
import Page from './Page'
import ProductPage from './ProductPage'
import SearchPage from './SearchPage'
import ApplyNowPage from './ApplyNowPage'
import PrintRatesPage from './PrintRatesPage'

export default {
  BlogArticlePage,
  BlogParentPage,
  GrandChildPage,
  LandingPage,
  OfferPage,
  Page,
  ProductPage,
  SearchPage,
  ApplyNowPage,
  PrintRatesPage
}
