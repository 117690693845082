import React, { useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import filter from 'lodash/filter'

import api from 'Helpers/api'
import { AppContext } from '../contexts'
import { useQueryParams } from 'Hooks'
import { RouterLinkTemplate, PaginationRenderer } from 'Components'
import { getDateWithFormat } from 'Helpers/utilities'
import ArrowRightSVG from 'SVGs/arrowRight.svg'

const SearchBar = ({ data }) => {
  const { appContext, setAppContext } = useContext(AppContext)
  const location = useLocation()
  const { pathname } = location
  const { Placeholder, Query, SetQuery } = data

  const handleSubmission = event => {
    event.preventDefault()
    setAppContext({ ...appContext, isLoading: true })
    api.getContentByFriendlyUrl(pathname, Query ? { q: Query } : null)
      .then(res => {
        setAppContext({ ...appContext, data: res, isLoading: false })
        if (typeof appInsights !== 'undefined' && document !== 'undefined') {
          // eslint-disable-next-line no-undef
          appInsights.trackPageView(res.MetaTitle || res.Name)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <form className="flex gap-6 items-center relative" onSubmit={handleSubmission}>
      <div className="w-full">
        <div className="relative text-gray-800 focus-within:text-gray-800">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <span className="p-2 focus:outline-none focus:shadow-outline">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-4 h-4 md:w-6 md:h-6 text-gray-500"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </span>
          </span>
          <span>
            <input type="text" name="q" value={Query} onChange={event => SetQuery(event?.target?.value)}
              className="w-full appearance-none shadow-inner placeholder-gray-600 py-2 lg:text-lg md:text-base sm:text-sm text-xxs font-body
              rounded-full border border-gray-300 md:pl-12 pl-10 pr-3 focus:outline-none focus:text-gray-800"
              placeholder={Placeholder} autoComplete="off" />
          </span>
        </div>
      </div>
    </form>
  )
}

const SearchBarMobile = ({ data }) => {
  const { appContext, setAppContext } = useContext(AppContext)
  const location = useLocation()
  const { pathname } = location
  const { Placeholder, Query, SetQuery } = data

  const handleSubmission = event => {
    event.preventDefault()
    setAppContext({ ...appContext, isLoading: true })
    api.getContentByFriendlyUrl(pathname, Query ? { q: Query } : null)
      .then(res => {
        setAppContext({ ...appContext, data: res, isLoading: false })
        if (typeof appInsights !== 'undefined' && document !== 'undefined') {
          // eslint-disable-next-line no-undef
          appInsights.trackPageView(res.MetaTitle || res.Name)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <form className="flex gap-6 items-center relative" onSubmit={handleSubmission}>
      <div className="w-full">
        <div className="relative text-gray-800 focus-within:text-gray-800">
          <span className="absolute inset-y-0 right-0 flex items-center pr-1">
            <span className="focus:outline-none focus:shadow-outline">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-4 h-4 md:w-6 md:h-6 text-gray-500"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </span>
          </span>
          <span>
            <input type="text" name="q" value={Query} onChange={event => SetQuery(event?.target?.value)}
              className="w-full appearance-none placeholder-gray-600 py-1 font-body
              border-b border-gray-300 pr-6 pl-1 focus:outline-none focus:text-gray-800"
              placeholder={Placeholder} autoComplete="off" />
          </span>
        </div>
      </div>
    </form>
  )
}

const HighlightedBlog = ({ data, inEditMode, readText }) => {
  if (!data) return null
  const { FeaturedImage, PublishedDate, Author, Title, Url } = data

  return (
    <div className="grid gap-3">
      {FeaturedImage && <div className="w-full rounded-xl aspect-w-16 aspect-h-9 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${FeaturedImage})` }} />}
      <span className="text-gray-500">{getDateWithFormat(new Date(PublishedDate))} | by {Author}</span>
      <h3 className="font-display font-medium lg:text-2xl md:text-lg text-base">{Title}</h3>
      <p>
        <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
          <span className="font-display md:text-lg text-base text-violet hover:text-violet-bright cursor-pointer">{readText}<ArrowRightSVG className="inline-block ml-2" width="20" height="20" />
          </span>
        </RouterLinkTemplate>
      </p>
    </div>
  )
}

const BlockCard = ({ data, inEditMode, readText, className = '' }) => {
  if (!data) return null
  const { FeaturedImage, Title, PublishedDate, Author, Url } = data
  return (
    <div className={`py-2 w-full md:w-1/2 lg:my-4 lg:w-1/3 ${className}`}>
      <article className="overflow-hidden rounded-lg shadow-lg h-full flex flex-col">
        {FeaturedImage && <div className="w-full aspect-w-16 aspect-h-9 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${FeaturedImage})` }} />}
        <div className="h-full flex flex-col py-2">
          <p className="mx-4 my-2 text-gray-500">{getDateWithFormat(new Date(PublishedDate))} | by {Author}</p>
          <header className="font-display font-medium mx-2 md:mx-4 h-full">
            <h3 className="lg:text-xl md:text-lg text-base lg:leading-6 md:leading-5">{Title}</h3>
          </header>
          <footer className="flex items-center justify-between leading-none px-2 md:px-4 py-2">
            <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
              <span className="font-display lg:text-lg md:text-base text-sm text-violet hover:text-violet-bright cursor-pointer">{readText}<ArrowRightSVG className="inline-block ml-2" width="20" height="20" />
              </span>
            </RouterLinkTemplate>
          </footer>
        </div>
      </article>
    </div>
  )
}

const paginate = (array, pageSize, pageNumber) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const BlogSearchWithRelatedArticlesCardBlock = ({ data, name, inEditMode, activeCategory }) => {
  const { RelatedArticles, BlogParentPages, ViewAllTopicsText, ReadText, SearchText, TopicText } = data
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const [query, setQuery] = useState(queryParams?.q ?? '')
  const relatedArticlesForPagination = RelatedArticles?.slice(1)
  const [pageNumber, setPageNumber] = useState(1)

  const [blogParents] = useState(() => {
    if (BlogParentPages?.length > 0) {
      const main = filter(BlogParentPages, (x) => !x.Category?.length)[0]
      return [main, ...filter(BlogParentPages, (x) => x.Category?.length)]
    }
    return null
  })

  return (
    <div className="container mx-auto max-w-screen-xl" data-epi-edit={name}>
      <div className="xl:mx-20 lg:mx-12 md:mx-8
                      md:py-4 py-2">
        <div className="hidden md:grid grid-cols-12 gap-12">
          <div className="lg:col-span-9 col-span-8">
            <HighlightedBlog data={RelatedArticles[0]} inEditMode={inEditMode} readText={ReadText} />
          </div>
          <div className="lg:col-span-3 col-span-4">
            <SearchBar data={{ Placeholder: SearchText, Query: query, SetQuery: setQuery }} />
            <div className="mx-4 font-body font-medium text-lg">
              <p className="text-gray-500 text-xl py-2">{TopicText}</p>
              {blogParents?.map((x, i) => {
                const { Url, Name, Category } = x
                return (
                  <p key={i}>
                    <span onClick={() => {
                      setQuery('')
                      setPageNumber(1)
                    }}>
                      <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
                        <span className={`${activeCategory?.Id === Category[0] ? 'text-violet' : ''}`}>{Category.length > 0 ? Name : ViewAllTopicsText}</span>
                      </RouterLinkTemplate>
                    </span>
                  </p>
                )
              })}
            </div>
          </div>
        </div>
        <div className="md:hidden px-4 pb-8 grid gap-8">
          <div className="border border-gray-400 p-6">
            <SearchBarMobile data={{ Placeholder: SearchText, Query: query, SetQuery: setQuery }} />
            <div className="font-body mt-4">
              <p className="text-gray-500 py-2">{TopicText}</p>
              <select className="blog-search-select appearance-none border font-medium py-2 text-center w-full"
                onChange={(e) => {
                  setQuery('')
                  setPageNumber(1)
                  navigate(e.target.value)
                }}>
                {BlogParentPages?.map((x, i) => {
                  const { Url, Name, Category } = x
                  return <option key={i} value={Url}>{Category.length > 0 ? Name : ViewAllTopicsText}</option>
                })}
              </select>
            </div>
          </div>
          <HighlightedBlog data={RelatedArticles[0]} inEditMode={inEditMode} readText={ReadText} />
        </div>
        <div className="flex flex-wrap my-6 -mx-1 lg:-mx-4 ">
            {relatedArticlesForPagination?.length > 0 && paginate(relatedArticlesForPagination, 9, pageNumber).map((item, i) => {
                  return <BlockCard key={i} data={item} inEditMode={inEditMode} readText={ReadText} className="md:px-4 sm:px-12 px-4" />
            })}
        </div>
        {relatedArticlesForPagination && relatedArticlesForPagination.length > 0 && <PaginationRenderer pageNumber={pageNumber} onPageNumberChange={(page) => setPageNumber(page)}
          totalPageNumber={relatedArticlesForPagination && relatedArticlesForPagination.length > 0 ? Math.ceil(relatedArticlesForPagination.length / 9) : 1} />}
      </div>
    </div>
  )
}

export default BlogSearchWithRelatedArticlesCardBlock
export { BlockCard }
