import React from 'react'

import { Markdown } from 'Components'

const RawContentBlock = ({ data, inEditMode }) => {
  const { Content } = data

  return (
    <div className="container max-w-screen-xl mx-auto">
      <div className="py-4 RawContentBlock xl:mx-32 lg:mx-28 md:mx-12 lg:pt-12 lg:pb-8 md:py-7">
        <Markdown data={Content} name='Content' inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(RawContentBlock)
