import React from 'react'

import { RouterLinkTemplate } from 'Components'

const getInlineClass = (style, size) => {
  let inlineClass = 'rounded-full font-medium font-body whitespace-nowrap'
  if (style === 'secondary') {
    inlineClass += ' border-2 border-black bg-white'
  } else if (style === 'secondary_violet') {
    inlineClass += ' border-2 border-violet hover:border-violet-light text-violet hover:text-violet-light bg-white'
  } else {
    inlineClass += ' bg-violet hover:bg-violet-dark border-2 border-violet hover:border-violet-dark text-white'
  }

  if (size === 'lg') {
    inlineClass += ' lg:text-xl sm:text-lg text-base lg:py-3 py-2 lg:px-8 md:px-6 px-5'
  } else if (size === 'sm') {
    inlineClass += ' lg:text-sm sm:text-xs text-xxs py-1 lg:px-6 md:px-4 px-3'
  } else if (size === 'xs') {
    inlineClass += ' lg:text-xs sm:text-xxs text-xxxs py-1 lg:px-7 md:px-4 px-3'
  } else {
    inlineClass += ' text-sm py-1 px-6 md:text-base md:py-2 md:px-8'
  }

  return inlineClass
}

export default function RoundedButtonBlock ({ data, name, inEditMode, overrideClass, flex }) {
  if (!data) {
    if (!inEditMode) return null
    return (
      <div data-epi-edit={name}>
        <button type="button" className={overrideClass ?? getInlineClass('main', 'md')}>Click to Edit</button>
      </div>
    )
  }

  const { Name, Style, Size, Url, UrlTarget } = data

  if (!inEditMode && (!Name || !Style)) {
    return null
  }

  const flexClass = flex ? 'flex' : ''

  return (
    <div data-epi-edit={name} className={`${flexClass}`}>
      <RouterLinkTemplate Path={Url} Target={UrlTarget} inEditMode={inEditMode}>
        <button type="button" className={overrideClass ?? getInlineClass(Style, Size)}>
          {(Name ?? '') === '' ? 'Click to Edit' : Name}
        </button>
      </RouterLinkTemplate>
    </div>
  )
}
