import React from 'react'
import { StaticRouter } from 'react-router-dom/server'
import { BrowserRouter } from 'react-router-dom'

import gliaInject from './Helpers/gliaInject';

import App from './App'

import './main.css'

export default function RootComponent({ data, header, footer, context, location, isAnnouncementHidden, isSecondaryAnnouncementHidden, defaultHeader, defaultFooter, exitSiteSettings }) {

  // global glia inject script.
  React.useEffect(() => {
    gliaInject.run();
  })

  // This is for main app
  const initialData = { data, header, footer, isAnnouncementHidden, isSecondaryAnnouncementHidden, defaultHeader, defaultFooter, exitSiteSettings }
  if (typeof window === 'undefined') {
    return (
      <StaticRouter {...{ context, location }}>
        <App {...initialData} />
      </StaticRouter>
    )
  }

  return (
    <BrowserRouter>
      <App {...initialData} />
    </BrowserRouter>
  )
}
