/**
 * Glia Inject
 */

const _SCRIPT_ID = 'glia_enabled';
const _SCRIPT_SRC = 'https://api.glia.com/salemove_integration.js';
const _SCRIPT_DELAY = 0;

/** 
 * Allows Glia to view iframe content by adding the Glia script to all iframes.
 */
export default {

    _tag: 'GliaInject',

    _mutation: null,
    
    /**
     * Runs the Glia inject script once, and then on observed DOM changes.
     * 
     * @param {Element | undefined} container
     */
    run(container) {

        this._stop();

        // Determine container.
        container = container || document;
        
        // Run once for container.
        this.runOnce(container);
        
        // Mutation observer will allow scripts to run in response to DOM changes.
        this._mutation = new MutationObserver(this._mutation_next.bind(this));
        
        // Configure mutation observer for potential new iframe elements.
        this._mutation.observe(container, {
            attributes: false,      // Set to true if mutations to attributes are to be observed.
            characterData: false,   // Set to true if mutations to data are to be observed.
            childList: true,        // Set to true if mutations to children are to be observed.
            subtree: true           // Set to true if mutations to not just, but also descendants are to be observed.
        });
    },

    /**
     * Runs the Glia inject script once.
     * 
     * @param {Element | undefined} container
     */
    runOnce(container) {

        // Determine container.
        container = container || document;

        // Determine container iframes.
        const iframes = container.getElementsByTagName('iframe');

        // Run for existing iframe elements within container.
        [...iframes].forEach(this._iframe_run.bind(this));
    },

    /**
     * Stops the Glia inject script.
     */
    stop() {

        this._stop();
    },

    _stop() {

        // Stop document observer(s).
        this._mutation?.disconnect();
        this._mutation = null;
    },

    /**
     * 
     * @param {MutationRecord[]} records
     */
    _mutation_next(records) {
        records.forEach(this._mutation_next_record.bind(this));
    },

    /**
     * 
     * @param {MutationRecord} record
     */
    _mutation_next_record(record) {

        const iframes = [];
        const divs = [];
        record.addedNodes?.forEach(node => {
            switch (node.nodeName) {
                case 'DIV':
                    divs.push(node);
                    break;
                case 'IFRAME':
                    iframes.push(node);
                    break;
            }
        });
        
        // Run for added divs.
        divs.forEach(this._div_run.bind(this));

        // Run for added iframe elements.
        iframes.forEach(this._iframe_run.bind(this));
    },

    /**
     * Gets the document element for the specified iframe.
     * @param {HTMLIFrameElement} iframe
     * @returns {Document | null} The iframe's document element if able/available, null otherwise.
     */
    _iframe_getDocument(iframe) {
        try {
            return iframe.contentDocument || iframe.contentWindow?.document || null;
        }
        catch (ex) {
            this._warn(ex);
        }
        return null;
    },

    /**
     * @param {HTMLDivElement} div
     * @returns
     */
    _div_run(div) {

        this.runOnce(div);
    },

    /**
     * @param {HTMLIFrameElement} iframe
     * @returns
     */
    _iframe_run(iframe) {

        // Validate input.
        if (!this._visible(iframe)) {
            return;
        }

        // Get iframe document.
        let iframeDocument = this._iframe_getDocument(iframe);
        if (iframeDocument == null) {
            return;
        }

        switch (iframeDocument.readyState) {
            case 'interactive':
            case 'complete':
                // If iframe document is ready, proceed to inject.
                setTimeout(() => this._iframe_document_inject(iframeDocument), _SCRIPT_DELAY);
                break;
            default:
                // Otherwise, listen for iframe document ready state change.
                iframeDocument.addEventListener('readystatechange', this._iframe_document_readyStateChanged.bind(this));
                return;
        }
    },

    /**
     * Determines whether the element is visible or potentially visible to the user.
     * @param {Element} el
     * @returns {boolean} 
     */
    _visible(el) {
        return el && el.offsetWidth > 0 && el.offsetHeight > 0;
    },

    _iframe_document_readyStateChanged(e) {
        let iframeDocument = e.currentTarget || e.target;
        switch (iframeDocument.readyState) {
            case 'interactive':
            case 'complete':
                // If iframe document is ready, proceed to inject.
                setTimeout(() => this._iframe_document_inject(iframeDocument), _SCRIPT_DELAY);
                break;
            default:
                break;
        }
    },

    /**
     * Injects the Glia script into the Document of the supplied HTMLIFrameElement reference.
     * 
    * @param {HTMLIFrameElement} iframe
    * @returns {boolean} True if successful.
    */
    _iframe_inject(iframe) {

        // Validate input.
        if (iframe == null) {
            return false;
        }

        // Get iframe document.
        let iframeDocument = this._iframe_getDocument(iframe);

        // Inject script into iframe document.
        return this._iframe_document_inject(iframeDocument);
    },

    /**
     * Injects the Glia script into the supplied Document reference.
     * 
     * @param {Document} iframeDocument
     * @returns {boolean} True if successful.
     */
    _iframe_document_inject(iframeDocument) {

        // Validate input.
        if (iframeDocument == null) {
            return false;
        }
        
        // Check for existing script.
        if (iframeDocument.getElementById(_SCRIPT_ID)) {
            return false;
        }
        
        try {

            // Create script.
            let script = iframeDocument.createElement('script');
            script.setAttribute('id', _SCRIPT_ID);
            script.setAttribute('src', _SCRIPT_SRC);
            script.setAttribute('async', '');

            // Inject script.
            return !!iframeDocument.head.appendChild(script);
        }
        catch (ex) {

            this._error(ex);
        }
        
        return false;
    },

    _log(message) {
        console.log(`${this._tag}: ${message}`);
    },
    _warn(message) {
        console.warn(`${this._tag}: ${message}`);
    },
    _error(message) {
        console.error(`${this._tag}: ${message}`);
    }
}